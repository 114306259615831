import React from "react";
import { bool, string } from "prop-types";
import { isMobile } from "react-device-detect";
import ResourceImages from "../../components/ImageQuerys/ResourceImages";
import Title from "../Base/Title";
import "./styles.css";

const ResourceSlide = (props) => {
  const {
    className,
    imageName,
    mobileImageName,
    featured,
    label,
    title,
    subtext,
    href,
  } = props;

  return (
    <div
      role="button"
      className={`${className} flex flex-col lg:flex-row gap-8 w-full resource-slide`}
      onClick={() => window.open(href, "_blank")}
    >
      <div className="lg:max-w-40-percent flex flex-col items-start justify-center">
        {featured && (
          <div className="text-white text-[12px] tracking-[0.2em]">
            FEATURED
          </div>
        )}
        {label && (
          <div className="text-white bg-primary py-4 px-6 rounded-large my-8">
            {label}
          </div>
        )}
        <Title className="text-white max-w-90-percent" level={isMobile ? 3 : 2}>
          {title}
        </Title>
        {subtext && <div className="text-white text-sm">{subtext}</div>}
      </div>

      <div className="w-full lg:max-w-60-percent">
        <ResourceImages
          className="max-h-[500px] w-auto rounded-large"
          imageName={isMobile ? mobileImageName : imageName}
        />
      </div>
    </div>
  );
};

ResourceSlide.propTypes = {
  className: string,
  imageName: string.isRequired,
  mobileImageName: string.isRequired,
  featured: bool,
  label: string,
  title: string.isRequired,
  subtext: string,
  href: string.isRequired,
};

ResourceSlide.defaultProps = {
  className: "",
  featured: false,
  label: null,
  subtext: null,
};

export default ResourceSlide;
