import React, { useState } from "react";
import { string, bool, arrayOf, shape } from "prop-types";
import Carousel from "nuka-carousel";
import ResourceSlide from "./resource-slide";

export const resourceSlideProps = {
  imageName: string.isRequired,
  mobileImageName: string.isRequired,
  featured: bool,
  label: string,
  title: string.isRequired,
  subtext: string,
};

export const resourceSlideDefaultProps = {
  featured: false,
  label: null,
  subtext: null,
};

const settings = {
  // autoplay: true,
  heightMode: "max",
  slidesToScroll: 1,
  slidesToShow: 1,
  withoutControls: true,
  wrapAround: true,
  dragging: true,
  cellSpacing: 60,
};

const ResourceSlider = ({ className, slides }) => {
  const [slideIndex, setSlideIndex] = useState(0);

  return (
    <section
      className={`
      ${className} max-w-1440 w-full rounded-large
      bg-black
      py-10 pt-20 lg:pt-24 pb-10 lg:pb-14
      px-4 md:px-8 lg:px-6 xl:px-14
    `}
    >
      <Carousel
        {...settings}
        slideIndex={slideIndex}
        afterSlide={(idx) => setSlideIndex(idx)}
      >
        {slides.map((slide) => (
          <ResourceSlide key={slide.title} {...slide} />
        ))}
      </Carousel>

      <div className="flex flex-row w-full mt-3 block">
        <div className="hidden lg:block lg:max-w-40-percent w-full" />
        <div className="w-full">
          <div className="relative w-full z-1 flex flex-row justify-center mt-2 lg:mt-4 lg:mr-[50%]">
            {slides.map((slide, idx) => {
              return (
                <div
                  key={slide.title}
                  className={`
                border border-white text-white
                rounded-full w-8 h-8 cursor-pointer
                flex flex-row items-center justify-center
                `}
                  style={{
                    border:
                      slideIndex === idx
                        ? "1px solid #fff"
                        : "1px solid transparent",
                  }}
                  onClick={() => setSlideIndex(idx)}
                >
                  &bull;
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

ResourceSlider.propTypes = {
  className: string,
  slides: arrayOf(shape(resourceSlideProps)).isRequired,
};

ResourceSlider.defaultProps = {
  className: "",
};

export default ResourceSlider;
